<template>
    <b-container class="pt-lg-5 pb-5">
        <section class="activity-section overflow-hidden">
            <b-row>
                <b-col cols="12" lg="8" class="order-1 order-lg-0">
                    <div class="activity-images-container mx-auto"
                        data-aos="fade-right"
                        data-aos-duration="3000"
                    >
                        <div class="image-2">
                            <div class="activity-image-card-border rounded">
                                <div class="activity-image-card rounded">
                                    <img
                                        v-if="activityDetails.imagePath"
                                        :src="
                                            $store.getters['app/domainHost'] +
                                                '/' +
                                                activityDetails.imagePath
                                        "
                                        class="w-100 rounded"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </b-col>
                <b-col cols="12" lg="4" class="order-0 order-lg-1">
                    <div
                        class="activity-description-container text-center text-lg-left w-100 rounded mx-auto py-1"
                        data-aos="fade-left"
                        data-aos-duration="2500"
                    >
                        <h3>{{ activityDetails.title }}</h3>
                        <span>
                            تاريخ :
                            {{
                                new Date(
                                    activityDetails.date
                                ).toLocaleDateString("en-UK")
                            }}
                        </span>
                    </div>
                </b-col>
            </b-row>
        </section>
        <section class="description-section rounded mt-4">
            <svg class="corner corner-1" width="400" height="400">
                <defs>
                    <linearGradient id="Gradient1">
                        <stop class="stop1" offset="0%"/>
                        <stop class="stop1" offset="25%"/>
                        <stop class="stop5" offset="100%"/>
                    </linearGradient>
                    <linearGradient id="Gradient2">
                        <stop class="stop5" offset="0%"/>
                        <stop class="stop1" offset="75%"/>
                        <stop class="stop1" offset="100%"/>
                    </linearGradient>
                </defs>
                <path
                    d="M70,20 h300 a10,10 0 0 1 10,10 v300 a10,0 10 0 0 1 -10"
                    fill="none"
                    stroke="url(#Gradient1)"
                    stroke-width="3"
                />
            </svg>
            <svg class="corner corner-2" width="400" height="400">
                <path
                    d="M20, 70 v300 a10,10 1 0 0 10,10 h300 a10,10 0 0 1 -10 0"
                    fill="none"
                    stroke="url(#Gradient2)"
                    stroke-width="3"
                />
            </svg>
            <h6
                class="ql-editor m-0 p-3"
                data-aos="fade-up"
                data-aos-duration="2500"
                v-html="activityDetails.description"
            ></h6>
        </section>
        <section class="py-5">
            <h4 class="text-white text-center">
                {{ t("website.otheractivities") }}
            </h4>
            <b-row class="py-3">
                <b-col
                    cols="12"
                    lg="4"
                    v-for="(activity, i) in otherActivities"
                    :key="i"
                    class="otherActivity pt-3 px-2 px-lg-4 mx-auto"
                >
                    <router-link :to="'/activities/' + activity.id" custom>
                        <img
                            data-aos="fade-up"
                            :data-aos-duration="1000 * (i + 1)"
                            :src="
                                $store.getters['app/domainHost'] +
                                    '/' +
                                    activity.imagePath
                            "
                            class="img-fluid"
                        />
                        <h4 class="activity-title">{{ activity.title }}</h4>
                    </router-link>
                </b-col>
            </b-row>
        </section>
    </b-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import { handleLangChange } from '@/libs/event-bus';
export default {
    props: {
        id: String
    },
    computed: {
        ...mapState({
            activityDetails: state => state.webActivities.activityDetails,
            otherActivities: state => state.webActivities.otherActivities
        })
    },
    metaInfo() {
        return {
            titleTemplate: "Awija - " + this.$t("website.activities") + ' ' + this.activityDetails.title,
            htmlAttrs: {
                lang: this.$i18n.locale
            },
            meta: [
                {
                    name: "description",
                    content: this.activityDetails.description
                },
                {
                    name: "keywords",
                    content: "keywords, keywords, keywords, ..."
                }
            ]
        };
    },
    setup() {
        const { t } = useI18nUtils();
        return { t };
    },
    created() {
        this.getWebsiteActivityDetails(this.id);
        handleLangChange(() => {
            this.getWebsiteActivityDetails(this.id);
        });
    },
    methods: {
        ...mapActions(["getWebsiteActivityDetails"])
    },
    watch: {
        id(id) {
            this.getWebsiteActivityDetails(id);
        }
    }
};
</script>

<style lang="scss">
*{
    color: #fff !important
}
.activity-section {
    color: #fff;
    .activity-images-container {
        height: 400px;
        position: relative;
        .image-2 {
            perspective: 500px;
            transform-style: preserve-3d;
            position: absolute;
            height: 100%;
            width: 100%;
            .activity-image-card-border {
                overflow: hidden;
                transform-origin: left center;
                transform: scale(0.9) rotate3d(-22, 6, 0, 10deg)
                    skew(8deg, 0deg);
                height: 100%;
                width: 100%;
                padding-top: 3px;
                padding-right: 2px;
                .activity-image-card {
                    height: 100%;
                    width: 100%;
                }
            }
            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                transform: scale(1.2) rotate3d(8, -6, 0, 28deg) skew(-3deg, 0deg)
            }
        }
    }
    .activity-description-container {
        h3 {
            color: #fff;
            margin: 0;
        }
        span {
            display: block;
            padding: 1rem 0;
        }
        p {
            padding: 0 0 2em;
            text-align: justify;
        }
    }
    
    @media screen and (max-width: 992px) {
        .activity-images-container {
            .image-2 {
                transform: translateY(0);
                top: 0;
                .activity-image-card-border {
                    transform: rotate(0deg) !important;
                }
                img {
                    transform: translateY(0);
                }
            }
        }
    }
}
.description-section {
    min-height: 400px;
    margin-left: -16px;
    margin-right: -16px;
    background: url("/media/images/site-images/section-bg.png") center center
        no-repeat;
    background-size: cover;
    position: relative;
}
.description-section ::v-deep .ql-editor p {
    line-height: 1.8;
}
.description-section ::v-deep .ql-editor * {
    color: #fff;
}
.otherActivity {
    &:before {
        background: #c4c4c4;
        position: absolute;
        content: "";
        width: 100%;
        height: 3px;
        top: 0;
        left: 0;
    }

    &:hover {
        &:before {
            background: var(--custom-blue-sky);
            box-shadow: -1px 0px 4px var(--custom-blue-sky),
                0px 1px 4px var(--custom-blue-sky);
        }
        h4 {
            opacity: 1;
        }
        img {
            opacity: 1;
        }
    }
    img {
        height: 200px;
        width: 100%;
        object-fit: cover;
        border-radius: 15px;
        opacity: 0.5;
    }
    h4 {
        opacity: 0;
        color: var(--custom-blue-sky);
        text-align: center;
        margin: 10px auto;
    }
}
[dir="ltr"] {
    .activity-section {
        .activity-images-container {
            .image-2 {
                .activity-image-card-border {
                    transform-origin: left center;
                    transform: scale(0.9) rotate3d(-22, 6, 0, -10deg) skew(8deg, 0deg);
                }
                img {
                    transform: scale(1.2) rotate3d(8, -6, 0, 28deg) skew(-3deg, 0deg)
                }
            }
        }
    }
}
</style>
